import "./../scss/index.scss";

import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "@popperjs/core";
import "bootstrap";

import "slick-carousel";
import hcSticky from "hc-sticky";
import AfterViewportJs from "@lysla/afterviewportjs";
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import "animate.css";
import WOW from "wow.js";

//#region animate.css triggered on viewport
var wow = new WOW({
  boxClass: "wow",
  animateClass: "animated",
  mobile: false,
  offset: 0,
});
wow.init();
//#endregion

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: "body",
    stickyClass: "stickybar--sticked",
  });
});
//#endregion
//#region offcavas mobile
$("[data-trigger]").on("click", function () {
  var trigger_id = $(this).attr("data-trigger");
  $(trigger_id).toggleClass("show");
  $("body").toggleClass("offcanvas-active");
});

$(".close-icon").on("click", function (e) {
  $(".navbar-collapse").removeClass("show");
  $("body").removeClass("offcanvas-active");
});
//#endregion
//#region sticky sidebars
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".fixed-share-container", {
    stickTo: "#fixed-share-container",
    top: 175,
  });
});
//#endregion

//#region slider
$(function () {
  $(".slider-brand").slick({
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    arrows: true,
    appendArrows: $(".slider-brand-arrow-container"),
    prevArrow: ".slider-brand-prev-arrow",
    nextArrow: ".slider-brand-next-arrow",
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  });
});
//#endregion
//#region gallery

Fancybox.bind('[data-fancybox="gallery-small"]', {});
Fancybox.bind('[data-fancybox="gallery"]', {});

//#endregion

//prova per form
document.addEventListener("DOMContentLoaded", function() {
  const formContatti = document.getElementById("formContatti");
  const confirmBtn = document.getElementById("confirmBtn");

  if (formContatti != null && confirmBtn != null) {
    confirmBtn.addEventListener("click", function(event) {
        console.log("funzione!!");

        event.preventDefault();
        grecaptcha.execute();
        if (checkCampi()) {
            console.log('formcontatti.submit');
            formContatti.submit();
        }
    });
  }

  const inputs = document.querySelectorAll("#formContatti input[type='text'], #formContatti textarea, #formContatti input[type='checkbox']");
  inputs.forEach(function(input) {
      input.addEventListener("change", function() {
          checkCampi();
      });
  });
});

function checkCampi() {
  console.log("Inizio - checkcampi()");
  const labelAlert = document.getElementById("alert-message");
  const formFields = ["name", "email", "messaggio", "privacy"];
  let isValid = true;

  formFields.forEach(function(fieldId) {
      const fieldValue = document.getElementById(fieldId).value;
      console.log(fieldValue);
      if (fieldValue == null || fieldValue.trim() == "") {
          labelAlert.style.display = "inline";
          isValid = false;
      }
  });

  if (isValid) {
      labelAlert.style.display = "none";
  }

  return isValid;
}